<template>
  <div class="row justify-content-center" v-loading="loading">
    <div class="col">
      <ul class="list-unstyled">
        <li>
          <small class="text-muted"><small>公司名称</small></small>
          <h4 class="mb-0">{{ data.org.company_name }}</h4>
        </li>
        <li>
          <small class="text-muted"><small>数据日期</small></small>
          <h4 class="mb-0"><b>{{ data.entry.year }}</b> : {{ toDateString(data.entry.start_date) }} ~ {{ toDateString(data.entry.end_date) }}</h4>
        </li>
        <li v-if="data.entry.status === 3">
          <small class="text-muted"><small>申请退回原因</small></small>
          <h4 class="mb-0 text-danger">{{ data.entry.modify_reason }}</h4>
        </li>
      </ul>
      <div class="row">
        <div class="col">
          <el-tabs type="card">
            <el-tab-pane label="统计数据">
                <el-table refs="datarows" row-key="id" 
                    header-row-class-name="thead-light"
                    header-cell-class-name="text-center"
                    style="width: 100%" max-height="500"
                    :data="data.rows" stripe>
                    <el-table-column prop="product_name" label="产品名称" min-width="160" class-name="pl-2">
                    </el-table-column>
                    <el-table-column label="产地">
                        <el-table-column prop="province" label="省份" min-width="110"></el-table-column>
                        <el-table-column prop="place" label="市、区、县" min-width="120"></el-table-column>
                    </el-table-column>
                    <el-table-column prop="amount" :label="this.dataTypeName(this.data.entry.data_type)+'金额(万元)'" min-width="90">
                        <template slot-scope="{row}">
                            {{ row.amount.toFixed(2) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="remark" label="备注">
                        <template slot-scope="{row}">
                            <small>{{ row.remark }}</small>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="附件">
              <div v-if="attachments.length>0">
                <h5><span class="text-success">{{ attachments.length }}</span>个附件可以查看，点击图片可以进行预览</h5>
                <el-image 
                  style="max-width: 100%; max-height: 300px"
                  class="border rounded p-1"
                  :src="attachments[0]" 
                  :preview-src-list="attachments">
                </el-image>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <hr class="mb-3" />
      <h3 class="text-muted">审核结果</h3>
      <el-form :model="model" :rules="rules" :show-message="false" ref="form">
        <div class="row">
          <div class="col">
            <el-form-item prop="status">
              <el-radio-group v-model="model.status">
                <el-radio label="1" class="radio-success">通过</el-radio>
                <el-radio label="2" class="radio-warning">拒绝</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </div>
        <div class="row mt-n3">
          <div class="col">
            <el-form-item prop="remark">
              <el-input type="textarea" v-model="model.remark" name="remark" placeholder="审核意见..."></el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div class="row mt-3">
        <div class="col text-right" v-if="this.data.entry.status === 0 || this.data.entry.status === 3">
          <a href="javascript:;" class="px-3 text-default" @click="cancelled">&lt; 取消</a>
          <el-button type="success" class="px-3" @click="submitForm">提交</el-button>
        </div>
        <div class="col text-right" v-if="this.data.entry.status === 1 || this.data.entry.status === 2">
          <a href="javascript:;" class="px-3 text-default" @click="submitForm">重新提交 &gt;</a>
          <el-button type="success" class="px-3" @click="cancelled">关闭</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'data-audit-form',
    props: {
      itemId: {
        type: Number,
        description: 'Model Id',
        default: null
      },
      saving: Boolean
    },
    data () {
      var checkStatus = (rule, value, cb) => {
        if (value === '1' || value === '2') cb()
        else cb(new Error('请选择审核结果'))
      }
      return {
        backendHost: process.env.VUE_APP_BACKEND_TARGET || 'https://guoqi.ycycsw.cn',
        loading: false,
        data: {
          entry: {
            year: '',
            date_range: [],
            start_date: '',
            end_date: '',
          },
          rows:[],
          attachments: [],
          org: {}
        },
        model: {
          id: this.itemId,
          status: null,
          remark: ''
        },
        rules: {
          status: [{validator: checkStatus, trigger: 'blur'}]
        }
      }
    },
    computed: {
      attachments() {
        return this.data.attachments.map(x => {
          return this.backendHost + x.file_name
        })
      }
    },
    methods: {
      dataTypeName (data_type) {
        switch (data_type) {
          case 0: return '采购';
          case 1: return '销售';
          default: return ''
        }
      },
      fetchData () {
        this.loading = true
        this.axios.get('datainputs/' + this.itemId).then((rep) => {
          if(rep.data.errcode === 0) {
            this.data = rep.data.data
            if (this.data.entry.status === 1 || this.data.entry.status === 2) {
              this.model.status = this.data.entry.status + ''
              this.model.remark = this.data.entry.remark
            } else {
              this.model.status = null
            }
          } else {
            this.$notifyErr('加载数据失败', rep.data.errmsg)
            this.$emit('cancelled', true)
          }
          this.loading = false
        })
        .catch((e) => {
          this.loading = false
          this.$notifyErr('加载数据失败', e.message)
          this.$emit('cancelled', true)
        })
      },
      cancelled () {
        this.$emit('cancelled', true)
      },
      submitForm () {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.save()
          } else {
            return false
          }
        })
      },
      save () {
        this.$emit('update:saving', true)
        this.loading = true
        this.axios.post('datainputs/audit', this.model).then((rep) => {
          if(rep.data.errcode === 0) {
            this.$emit('saved')
          } else {
            this.$notifyErr('提交数据失败', rep.data.errmsg)
          }
          this.$emit('update:saving', false)
          this.loading = false
        })
        .catch((e) => {
          this.loading = false
          this.$notifyErr('提交数据失败', e.message)
          this.$emit('update:saving', false)
        })
      }
    },
    mounted () {
      if (this.itemId) {
        this.fetchData()
      }
    }
  }
</script>
<style scoped>
  .el-form-item.is-error .el-radio-group {
    border-bottom: 3px solid #da4a16;
  }
</style>